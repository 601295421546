<!-- 编辑活动管理 -->
<template>
  <el-dialog
    title="编辑活动管理"
    :visible.sync="editShow"
    width="50%"
    @close="closeDialog"
    :z-index="12"
    :destroy-on-close="true"
    :close-on-click-modal="false"
  >
    <ax-form ref="formBox" :formBuilder="formBuilder" @change="onFormChange">
      <div slot="AwardQuota">
        <div class="button2">
          <a-button @click="addItem">添加</a-button>
        </div>
        <a-form-model
          :model="form"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
        >
          <div v-for="(item, index) in formItems" :key="index">
            <div style="display: flex; justify-content: space-around">
              <!-- 获奖名称 -->
              <a-form-model-item label="获奖名称">
                <a-input
                  v-model="item.awardName"
                  placeholder="例如：一等奖"
                  :style="{ width: '150px' }"
                />
              </a-form-model-item>
              <!-- 获奖名额 -->
              <a-form-model-item
                label="获奖名额"
                :style="{ marginLeft: '20px' }"
              >
                <a-input
                  v-model="item.awardCount"
                  placeholder="例如:3"
                  :style="{ width: '150px' }"
                />
              </a-form-model-item>
              <!-- 对应的奖品 -->
              <a-form-model-item
                label="获奖奖品"
                :style="{ marginLeft: '20px' }"
              >
                <a-input
                  v-model="item.awardPrize"
                  placeholder="例如:100块钱"
                  :style="{ width: '150px' }"
                />
              </a-form-model-item>
              <a-button @click="deleteItem(index)">删除</a-button>
            </div>
          </div>
        </a-form-model>
      </div>
    </ax-form>
    <div style="display: flex; justify-content: flex-end">
      <a-button type="primary" @click="onSubmit" :loading="saveType">
        保存
      </a-button>
      <a-button style="margin-left: 20px" @click="closeDialog"> 取消 </a-button>
    </div>
  </el-dialog>
</template>
<script>
import api from "./api";
const type = [
  { label: "公益活动", value: "1" },
  { label: "教育讲座", value: "2" },
  { label: "文艺表演", value: "3" },
  { label: "体育竞赛", value: "4" },
  { label: "节日庆典", value: "5" },
  { label: "防灾演练", value: "6" },
  { label: "环保行动", value: "7" },
  { label: "其他", value: "8" },
];
const formList = [
  {
    label: "活动名称",
    type: "",
    model: "eventName",
    options: { placeholder: "请输入", showTime: true, maxLength: 20 },
    col: { span: 24 },
    rules: [
      {
        required: true,
        message: "活动名称不能为空",
      },
    ],
  },
  {
    label: "活动类型",
    type: "select",
    model: "eventType",
    options: {
      options: type,
      placeholder: "请输入",
    },
    col: { span: 24 },
    rules: [{ required: true, message: "活动类型不能为空" }],
  },
  {
    label: "举办地点",
    type: "",
    model: "location",
    options: { placeholder: "请输入", showTime: true, maxLength: 50 },
    col: { span: 24 },
    rules: [
      {
        required: true,
        message: "举办地点不能为空",
      },
    ],
  },

  {
    label: "举办单位",
    type: "",
    model: "organization",
    options: {
      placeholder: "请输入",
      showTime: true,
      maxLength: 50,
    },
    col: { span: 24 },
    rules: [
      {
        required: true,
        message: "举办单位不能为空",
      },
    ],
  },
  {
    label: "报名时间",
    type: "rangePicker",
    model: "startTime",
    col: { span: 12 },
    options: { showTime: true },
    rules: [{ required: true, message: "请选择" }],
  },

  {
    label: "举办时间",
    type: "rangePicker",
    model: "endTime",
    col: { span: 12 },
    options: { showTime: true },
    rules: [{ required: true, message: "请选择" }],
  },
  {
    label: "限制人数",
    type: "inputNumber",
    model: "limitPeople",
    col: { span: 12 },
    options: { placeholder: "请输入", maxLength: 4, min: 1, max: 3000 },
    rules: [
      {
        required: true,
        message: "请输入",
        pattern: /^(?:[1-9]|\d{2}|\d{3}|[12]\d{3}|3000)$/,
      },
    ],
  },
  {
    label: "所属网格",
    type: "cascader",
    model: "gridId",
    options: { placeholder: "请输入" },
    col: { span: 12 },
    rules: [{ required: true, message: "请选择所属网格" }],
  },
  {
    label: "活动加积分",
    type: "inputNumber",
    model: "pointsRewardOne",
    options: {
      placeholder: "请输入",
      showTime: true,
      min: 0,
      maxLength: 50,
      defaultValue: 0,
    },
    col: { span: 12 },
    rules: [
      {
        required: false,
        message: "请输入",
      },
    ],
  },
  {
    label: "评分加积分",
    type: "inputNumber",
    model: "pointsRewardTwo",
    options: {
      placeholder: "请输入",
      showTime: true,
      min: 0,
      maxLength: 50,
      defaultValue: 0,
    },
    col: { span: 12 },
    rules: [
      {
        required: false,
        message: "请输入",
      },
    ],
  },
  {
    label: "活动内容",
    type: "textarea",
    model: "content",
    options: { minRows: 4, maxRows: 4, placeholder: "请输入", maxLength: 200 },
    col: { span: 24 },
    rules: [{ required: true, message: "活动内容不能为空" }],
  },
  {
    label: "获奖详情",
    type: "",
    model: "AwardQuota",
    col: { span: 24 },
    options: { minRows: 4, maxRows: 4 },
  },
];

export default {
  components: {},
  data() {
    return {
      api,
      gridId: "",
      id: "",
      saveType: false,
      editShow: false,
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },
      startTime: [],
      endTime: [],
      eventState: "",
      eventStartTime: "",
      eventEndTime: "",
      registrationStartTime: "",
      registrationEndTime: "",
      form: {
        awardName: "",
        awardCount: "",
        awardPrize: "",
      },
      // formItems: [{ awardName: "一等奖", awardCount: 1, awardPrize: "10元" }],
      formItems: [{ awardName: "", awardCount: "", awardPrize: "" }],
      formBuilder: this.$common.initGridFormData(
        formList,
        { layout: "horizontal", labelWidth: 125 },
        { col: { span: 8 } }
      ),
    };
  },
  watch: {},
  computed: {},
  created() {},
  mounted() {},
  methods: {
    // 打开弹窗
    openModal(record = {}) {
      this.editShow = true;
      this.id = record.id;
      this.owningGrid();
      this.$nextTick(() => {
        this.getData();
      });
    },
    addItem() {
      this.formItems.push({ awardName: "", awardPrize: "", awardCount: "" });
    },
    deleteItem(index) {
      this.formItems.splice(index, 1);
    },
    getRewards() {
      return this.formItems.map((item) => ({
        awardName: item.awardName,
        awardCount: item.awardCount,
        awardPrize: item.awardPrize,
      }));
    },
    onFormChange(e, type, fromApi = false) {
      // 报名时间
      if (type === "startTime") {
        let times = e.split(",");
        let registrationStartTime = times[0].trim();
        let registrationEndTime = times[1].trim();

        // 这里检测 fromApi 是否为 true
        if (
          !fromApi &&
          this.eventStartTime &&
          new Date(registrationEndTime) > new Date(this.eventStartTime)
        ) {
          // 清空数据
          this.registrationStartTime = "";
          this.registrationEndTime = "";
          this.startTime = [];
          this.$refs.formBox.setFieldsValue({ startTime: "" });
        } else {
          this.registrationStartTime = registrationStartTime;
          this.registrationEndTime = registrationEndTime;
        }
      }

      // 活动时间
      if (type === "endTime") {
        let times = e.split(",");
        let eventStartTime = times[0].trim();
        let eventEndTime = times[1].trim();

        // 这里也检测 fromApi 是否为 true
        if (
          !fromApi &&
          this.registrationEndTime &&
          new Date(eventStartTime) < new Date(this.registrationEndTime)
        ) {
          // 清空数据
          this.eventStartTime = "";
          this.eventEndTime = "";
          this.endTime = [];
          this.$refs.formBox.setFieldsValue({ endTime: "" });
        } else {
          this.eventStartTime = eventStartTime;
          this.eventEndTime = eventEndTime;
        }
      }
    },

    //查看
    async getData() {
      const res = await api.getById(this.id);
      this.$refs.formBox.resetFields();
      this.eventState = res.data.eventState;
      if (
        this.eventState === "活动报名中" ||
        this.eventState === "活动待开始"
      ) {
        this.$refs.formBox.setOptions(["eventName"], "disabled", true);
        this.$refs.formBox.setOptions(["eventType"], "disabled", true);
        this.$refs.formBox.setOptions(["startTime"], "disabled", true);
        this.$refs.formBox.setOptions(["organization"], "disabled", true);
        this.$refs.formBox.setOptions(["limitPeople"], "disabled", true);
        this.$refs.formBox.setOptions(["gridId"], "disabled", true);
        this.$refs.formBox.setOptions(["pointsRewardOne"], "disabled", true);
        this.$refs.formBox.setOptions(["pointsRewardTwo"], "disabled", true);
        this.$refs.formBox.setOptions(["content"], "disabled", true);
      }
      const formItems = res.data.rewards;
      // console.log(formItems);
      this.formItems = formItems; // 将数据赋值给 formItems
      // 替换中间的 - 为 ,
      // 假设这是你的时间字符串
      const registrationTime = res.data.registrationTime;
      // 使用正则表达式替换任意时间（HH:MM）和后面的 -
      const starttime = registrationTime.replace(/(\d{2}:\d{2})-/, "$1,");

      const eventTime = res.data.eventTime;
      const endTime = eventTime.replace(/(\d{2}:\d{2})-/, "$1,");
      // 这里可以调用 onFormChange 来处理 API 值
      this.onFormChange(starttime, "startTime", true);
      this.onFormChange(endTime, "endTime", true);
      try {
        this.gridId = res.data.gridId; // 假设这是你的目标 ID
        const gridTreeRes = await api.getTreeList();
        if (gridTreeRes.success) {
          const gridTreeData = this.processData(gridTreeRes.data); // 处理数据
          //找到父级 //递归地遍历树结构数据，并构建从根节点到目标节点的路径
          const findNodePath = (data, targetValue, path = []) => {
            for (const node of data) {
              const currentPath = [...path, node.value];
              if (node.value === targetValue) {
                return currentPath;
              }
              if (node.children && node.children.length > 0) {
                const result = findNodePath(
                  node.children,
                  targetValue,
                  currentPath
                );
                if (result) {
                  return result;
                }
              }
            }
            return null;
          };
          // 模拟获取的gridId
          //所有id
          const nodePath = findNodePath(gridTreeData, this.gridId);
          console.log(nodePath, "905"); //所有层级id数组
          this.$refs.formBox.setFieldsValue({
            ...res.data,
            gridId: nodePath,
            startTime: starttime,
            endTime: endTime,
          });
        } else {
          console.error("获取网格树数据失败:", gridTreeRes.message);
        }
      } catch (error) {
        console.error("获取所属网格数据异常:", error); // 输出异常信息
      }
    },

    // 获取所属网格数据
    async owningGrid() {
      try {
        const res = await api.getTreeList(); // 调用接口获取数据
        if (res.success) {
          // 如果接口请求成功
          const options = this.processData(res.data); // 处理数据
          this.$refs.formBox.setFormItemProp("gridId", {
            options: {
              options,
              allowClear: true,
              placeholder: "请选择",
              changeOnSelect: true,
            },
          });
        } else {
          console.error("获取所属网格数据失败:", res.message); // 输出错误信息
        }
      } catch (error) {
        console.error("获取所属网格数据异常:", error); // 输出异常信息
      }
    },
    // 处理数据为级联选择器格式
    processData(data) {
      return data.map((item) => ({
        value: item.id,
        label: item.name,
        children: item.children ? this.processData(item.children) : [], // 递归处理子节点
      }));
    },

    //提交
    async onSubmit() {
      this.$refs.formBox.form.validateFields(async (err, value) => {
        if (err) return;
        value.id = this.id;
        const rewards = this.getRewards();
        value.rewards = rewards;
        value.gridId = value.gridId[value.gridId.length - 1];
        value.eventStartTime = this.eventStartTime;
        value.eventEndTime = this.eventEndTime;
        value.registrationStartTime = this.registrationStartTime;
        value.registrationEndTime = this.registrationEndTime;
        console.log(value);
        // 判断是否有新的 gridId 值，如果有则使用新值，没有则使用初始值
        this.saveType = true; // 验证通过后再设置
        try {
          const res = await api.update(value);
          if (res.status === 200) {
            this.$message.success("编辑成功");
            // 提交成功后关闭弹窗
            this.saveType = false; // 验证通过后再设置
            this.closeDialog();
            this.refreshTable();
          } else {
            this.$message.error("编辑失败");
            this.saveType = true; // 验证通过后再设置
          }
        } catch (err) {
          console.log(error);
          this.$message.error("编辑失败");
          this.saveType = false;
        }
      });
    },
    //关闭弹窗
    closeDialog() {
      this.editShow = false;
      this.eventState = "";
      this.$refs.formBox.setOptions(["eventName"], "disabled", false);
      this.$refs.formBox.setOptions(["eventType"], "disabled", false);
      this.$refs.formBox.setOptions(["startTime"], "disabled", false);
      this.$refs.formBox.setOptions(["organization"], "disabled", false);
      this.$refs.formBox.setOptions(["limitPeople"], "disabled", false);
      this.$refs.formBox.setOptions(["gridId"], "disabled", false);
      this.$refs.formBox.setOptions(["pointsRewardOne"], "disabled", false);
      this.$refs.formBox.setOptions(["pointsRewardTwo"], "disabled", false);
      this.$refs.formBox.setOptions(["content"], "disabled", false);
    },
    //刷新代码
    refreshTable() {
      this.$emit("refreshTable");
    },
  },
};
</script>
    
<style lang="less" scoped>
/deep/.ant-modal {
  top: 0;
}
.interact {
  margin-top: 2%;
  margin-bottom: 2%;
}
.button2 {
  display: flex;
  justify-content: space-between;
}
</style>
    